import React, { useEffect, useState } from 'react';
import useWindowDimensions from '../hooks/useWindowDimension.js';
import '../format.css';

export default function Navbar() {
  const [stickyClass, setStickyClass] = useState('');
  const [activeSection, setActiveSection] = useState('');
  const { height } = useWindowDimensions();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Add smooth scroll to anchor links
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const targetId = this.getAttribute('href').substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          window.scrollTo({
            top: targetElement.offsetTop - 75, // Adjust as needed
            behavior: 'smooth'
          });
        }
      });
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const scrollThreshold = height * 0.7;
    
    if (scrollY > scrollThreshold) {
      setStickyClass('sticky-nav');
    } else {
      setStickyClass('');
    }

    const sectionIds = ['startseite', 'termine', 'bilder', 'kontakt', 'überuns'];
    const active = sectionIds.find(id => {
      const element = document.getElementById(id);
      if (element) {
        const rect = element.getBoundingClientRect();
        return rect.top <= height * 0.5 && rect.bottom >= height * 0.5;
      }
      return false;
    });

    setActiveSection(active || '');
  };

  return (
<div className={`navbar ${stickyClass} nav ${activeSection ? 'active-section' : ''}`}>
  <nav>
    <ul>
      <li><a className={activeSection === 'startseite' ? 'active-section' : ''}href="#startseite">Startseite</a></li>
      <li><a className={activeSection === 'überuns' ? 'active-section' : ''} href="#überuns">Über uns</a></li>
      <li><a className={activeSection === 'termine' ? 'active-section' : ''} href="#termine">Terminkalender</a></li>
      <li><a className={activeSection === 'bilder' ? 'active-section' : ''} href="#bilder">Bilder</a></li>
      <li><a className={activeSection === 'kontakt' ? 'active-section' : ''} href="#kontakt">Kontakt</a></li>
    </ul>
  </nav>
</div>
  );
}
