import React, { Component } from 'react';
import '../format.css';

class Calendar extends Component {
    state = {  } 
    render() { 
        return (

        <div>
            <article>
                <h2 className='mittig color'>Terminkalender</h2>
                <div id='termine' className="calender">
                    <iframe src="https://calendar.google.com/calendar/embed?src=feuerhexenwis%40gmail.com&ctz=Europe%2FBerlin" scrolling="no"></iframe>
                </div>
            </article>
            <p></p>
            <p></p>
        </div>
        );
    }
}
 
export default Calendar;