import React, { Component } from 'react';
import '../format.css';

class Footer extends Component {

  handleImpressumClick = (event) => {
    event.preventDefault();
    if (this.props.onImpressumClick) {
      this.props.onImpressumClick();
    }
  };

  render() {
    return (
      <footer>
        <p>
          <a href="https://www.instagram.com/feuerhexenwis/" target="_blank">
            <img alt="Instagram" src="../pictures/insta.png" width="30px" height="30px" />
          </a>
        </p>
        <p>
          <a href="#" onClick={this.handleImpressumClick}>Impressum</a>
        </p>
        <p>Copyright © 2023 Feuerhexen - Weil im Schönbuch e.V.</p>
      </footer>
    );
  }
}

export default Footer;