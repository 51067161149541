import React, { Component, useState } from 'react';

class Contact extends Component {
    state = {  } 
    render() { 
        return (
            <div id= 'kontakt' className='Content'>
            <article>
            <h2 className='mittig color'>Kontaktformular</h2>
            <p>Liebe Besucherinnen und Besucher,<br/>
            <br/>
            aktuell steht unser Kontaktformular leider nicht zur Verfügung.<br/> 
            Falls Sie Fragen haben oder weitere Informationen benötigen, möchten wir Sie bitten, 
            sich direkt per E-Mail an uns zu wenden. Sie erreichen uns unter <a className='Emaillink' href='mailto:info@fh-wis.de.'>info@fh-wis.de.</a>
            <br/>
            <br/>
            Wir entschuldigen uns für eventuelle Unannehmlichkeiten und arbeiten mit Hochdruck an einer Lösung. 
            <br/>
            <br/>
            Vielen Dank für Ihr Verständnis.</p>
            <br/>
            <img className='mittigimg' src="../pictures/DSC_0628.jpeg" />
            <br/>
            </article>
            </div>);
    }
}
 
export default Contact;