import React, { Component } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';

class Index extends Component {
    state = {  } 
    render() { 
        return (
            <div id='startseite' className='Content'>
                <article>
                    <h2 className="mittig">Die Feuerhexen Weil im Schönbuch begrüßen dich auf unserer neuen Website!</h2>
                    <img className='mittigimg' src='../pictures/pyramide_tanz.JPG'/>
                </article>

                <p></p>

                <article className='news'>
                    <h2 className='mittig color'>Fasnetsauftakt der Feuerhexen</h2>
                    <div className='bild-container-left'>
                        <img className='bild-stil' src='../news/Bild1.JPG' alt='Bild 1'/>
                    </div>                    
                    <div className='bild-container-right'>
                        <img className='bild-stil' src='../news/Bild2.JPG' alt='Bild 2'/>
                    </div>
                    <p>Die närrische Zeit startete für uns wie gewohnt am 05.01. und für die nächste Hexengeneration stand die Hexentaufe an. 
                        Prüfung um Prüfung kämpften sich unsere Täuflinge durch und wir freuen uns, dass alle fünf diese mit Bravour bestanden haben und sich so ihre Maske verdient haben.</p>
                    <p>Pünktlich um 00:00 Uhr wurden dann von unserem Vorstand Pascal Bauer die Masken abgestaubt, sodass wir mit Vollgas in die neue Kampagne starten konnten.</p>
                    <p>Hierfür haben wir von der Firma Klenk Textilveredelung Beschriftung und Lack ein neues Narrenschild mit unserem Wappen bekommen, 
                        welches uns mit Sicherheit bei den kommenden Umzügen schon von weitem ankündigt.</p>
                    <p>Vielen Dank an Fritz und Alex!</p>
                    <p>Auch das zweite Fasnetswochenende mit drei tollen Veranstaltungen liegt nun bereits hinter uns. Bei unseren Nachbarn, den „Freien Narren Dettenhausen“, 
                        hatten wir unseren ersten Auftritt mit unserem Hexentanz, für den wir nicht nur Tanzschritte, sondern auch wieder Pyramiden einstudiert haben. 
                        Am Sonntag stand dann bei Kaiserwetter der erste Umzug des Jahres für uns an.</p>

                    <p>Die Vorfreude auf die kommenden Veranstaltungen ist bei uns allen riesig. Hast du auch Lust bekommen? 
                        Dann trag dir auf jeden Fall schonmal den „Schmotziga Dorschdich“ am 08.02. im Kalender ein. 
                        Hier findet traditionell um 11:11 Uhr unser Rathaussturm statt und wir freuen uns über viele Besucher.</p>
                </article>
                <p></p>
                <article>
                    <div>
                        <img className='mittig-einzel-img' src='../news/FH_Flyer_Rathaussturm_24.jpg' />
                    </div>
                </article>
            </div>
        );
    }
}
 
export default Index;