import React, { Component } from 'react';

class About extends Component {
    state = {  } 
    render() { 
        return (

            <div id='überuns'>
            <br></br>
            <br></br>
            <article>
            <h2 class="mittig">Zunftbeschreibung Feuerhexen – Weil im Schönbuch e.V.</h2>
                <h3>1. Vorstand und Zunftmeister Pascal Bauer</h3>
                <h4>Gründung am 26.11.2015</h4>
                <p>Weil im Schönbuch – Ein Ort ohne Narrenzunft trotz eines narreten Bürgermeisters aus Bad Buchau und ein paar
                Narren aus Fasnets-Hochburgen wie Riedlingen oder Weil der Stadt – das ist UNDENKBAR!</p>
                <p>logischer Schluss: die Gründung einer Narrenzunft 2015</p>
                <h4>Vereinseintragung e.V. am 3.1.2018</h4>
                <p>Aktuell 57 <a href="mitglieder.html"><strong>Mitglieder</strong> </a> und Hästräger  und einem Binaerhexer.</p>
            </article>
            <p></p>
            <article>
            <h2 class="mittig">Geschichte</h2>
                <p>…ein kleiner eingeklebter Zettel im ältesten Taufbuch Weils aus dem 17. Jahrhundert erinnert heute noch an eine
                entsetzliche Brandkatastrophe während der Kirchweihfeier in Weil im Schönbuch.</p>
                <p>Am 9. April 1559 als die Bürger Weil im Schönbuchs die Kirchweih feierten und sich bei Tanz, Spiel und Wein
                vergnügten, wurde die gute Stimmung durch Brandgeruch jäh unterbrochen.<br></br> Sie eilten alle zur Brandstätte um diese
                zu löschen. Dabei mussten die Bürger erkennen, dass gleichzeitig an allen Enden des Dorfes die Flammen
                emporschlugen. Schon bald standen mehrere Häuserreihen lichterloh in Flammen. Als sie erkannten, dass sie den
                Flammen nicht Herr werden würden, flüchteten sie mit ihren Habseligkeiten in die Kirche in der Hoffnung, dort in
                Sicherheit zu sein. Auch der Pfarrer ließ seine Bücher, Urkunden und Schriften aus dem Pfarrhaus in die Kirche
                bringen. Doch das Feuer griff auch auf die Kirche über und zerstörte was gerettet werden sollte und innerhalb
                weniger Stunden wurde ganz Weil ein Raub der Flammen.</p>
                <p>Noch Tage später fand man im ganzen Schönbuch Blätter von des Pfarrers Schriften, die der Sturm der Feuersbrunst
                davongetragen hatte. Wichtige geschichtliche Dokumente wurden so vernichtet.</p>
                <p>Am Ende wurden 111 Häuser und Höfe zerstört – der Wiederaufbau dauerte 20 Jahre.</p>
                <p>An diesem Tag hat der Brandstifter Enderlin Seitz ganz Weil zerstört. Er wurde festgenommen und gestand unter
                der Folter, dass es ihm Freude bereitet hatte Weil verbrennen zu sehen. Graf Karl von Hohenzollern ließ Enderlin
                Seitz daraufhin bei lebendigem Leib verbrennen.</p>
                <p>Seit dem Jahr 2015 nun vertreiben die Feuerhexen aus Weil im Schönbuch die bösen Geister, die den Brandstifter
                von 1559 leiteten, in der Fasnetszeit für das kommende Jahr aus Weil, bis zum Beginn der nächsten 5. Jahreszeit…</p>

            </article>
            <p></p>
            <p></p>
            </div>

        );
    }
}
 
export default About;