import React, { useState, useEffect } from 'react';

const Darkmode = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    // Hier könntest du auch localStorage verwenden, um den Dark Mode zwischen den Sitzungen beizubehalten
    const isDarkMode = localStorage.getItem('dark-mode') === 'true';
    setDarkMode(isDarkMode);
  }, []);

  useEffect(() => {
    document.body.classList.toggle('dark-mode', darkMode);
    localStorage.setItem('dark-mode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <div className= 'dark-mode-switch'>
    <div className="switch" onClick={toggleDarkMode}></div>
    </div>  
  );
};

export default Darkmode;
