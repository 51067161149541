import React, { Component } from 'react';
import CookieSkript from '../cookieSkript';

class Cookie extends Component {
    state = {};

    render() {
        return (
            <div id='cookies'>
                <div className='container'>
                    <div className='subcontainer'>
                        <div className='cookies'>
                            <p>Diese Website verwendet Cookies, um sicherzustellen, 
                                dass Sie die bestmögliche Erfahrung auf unserer Website erhalten. Durch die Nutzung unserer Website 
                                stimmen Sie der Verwendung von Cookies gemäß unserer 
                            <a href='../Cookie-Richtlinien.txt'> Cookie Richtlinien </a>
                             zu.</p>
                            <button id='cookies-btn'>That's fine!</button>
                        </div>
                    </div>
                </div>
                <CookieSkript />
            </div>
        );
    }
}

export default Cookie;