import React, { Component } from 'react';
import Logo from './components/logo';
import Navbar from './components/navbar';
import './format.css';
import Darkmode from './darkmode';
import Index from './components';
import Calendar from './components/calendar';
import Footer from './components/footer';
import Pictures from './components/pictures';
import Contact from './components/contact';
import Impressum from './components/impressum';
import About from './components/about';
import Cookie from './components/cookie';

class App extends Component {
  state = { 
    showImpressum: false,
   } 

  toggleImpressum = () => {
    this.setState((prevState) => ({
      showImpressum: !prevState.showImpressum,
    }));
  };

  render() { 
    return <React.Fragment>
          <Darkmode/>
          <Cookie/>
          <Logo/>
          <Navbar/>
          <Index/>
          <About/>
          <Calendar/>
          <Pictures/>
          <Contact/>
          {this.state.showImpressum && <Impressum/>}
          <Footer onImpressumClick={this.toggleImpressum}/>
          </React.Fragment>;
  }
}
 
export default App;