import React, { Component } from 'react';
import '../format.css';

class Logo extends Component {
    state = {  } 
    render() { 
        return  (  
            
        <div className="App">
        <header className="App-header">
          <a target="_blank">
          <img src='../Logo.png' className="App-logo" alt="logo" />
          </a>
          <p>
            Johla, Laufa, Stenga <br/> mit ´nem riesa Zenga <br/> Abends no was drenga <br/> nach der Kampagne im Elend versenga
          </p>
        </header>
      </div>);
    }
}

export default Logo;