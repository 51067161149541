
import React from 'react';
import Slideshow from '../slideshow';
import '../format.css';

const Pictures = () => {
  const imagePaths = [
    '../slideshow/bild1.JPG',
    '../slideshow/bild2.JPG',
    '../slideshow/bild3.JPG',
    '../slideshow/bild4.JPG',
    '../slideshow/bild5.JPG',
    '../slideshow/bild6.JPG',
    '../slideshow/bild7.JPG',
    '../slideshow/bild8.JPG',
    '../slideshow/bild9.JPG',
    '../slideshow/bild10.JPG',
    '../slideshow/bild11.JPG',
    '../slideshow/bild12.JPG',
    '../slideshow/bild13.JPG',
    '../slideshow/bild14.JPG',
    '../slideshow/bild15.JPG',
    '../slideshow/bild16.JPG',
    '../slideshow/bild17.JPG',
    '../slideshow/bild18.JPG',
    '../slideshow/bild19.JPG',
    '../slideshow/bild20.JPG',
    '../slideshow/bild21.JPG',
    '../slideshow/bild22.JPG',
    '../slideshow/bild23.JPG',
    '../slideshow/bild24.JPG',
    '../slideshow/bild25.JPG',
    '../slideshow/bild26.JPG',
    '../slideshow/bild27.JPG',
    '../slideshow/bild28.JPG',
    '../slideshow/bild29.JPG',
    '../slideshow/bild30.JPG',
    '../slideshow/bild31.JPG',
    '../slideshow/bild32.JPG',
    '../slideshow/bild33.JPG',
    // ... weitere Bildpfade ...
  ];

  return (
    <div id='bilder'>
    <article>
    <div>
      <h2 className='mittig color'>Bildergalerie</h2>
      <Slideshow images={imagePaths} />
    </div>
    
    </article>
    <p></p>
    </div>
  );
};

export default Pictures;