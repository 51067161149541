import React, { useState, useEffect } from 'react';
import './format.css';

const Slideshow = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatischer Wechsel der Bilder alle 3 Sekunden
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [images.length]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="mittig">
      <img className="slideshowimg" src={images[currentIndex]} alt={`Bild ${currentIndex + 1}`} />
    <p></p>
      <div className="navigation">
        <button onClick={goToPrevious}>Zurück</button>
        <button onClick={goToNext}>Weiter</button>
      </div>
    </div>
  );
};

export default Slideshow;