import React, { useState, useEffect } from 'react';

const CookieSkript = () => {
    const setCookie = (cName, cValue, expDays) => {
        const date = new Date();
        date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
        const expires = "expires=" + date.toUTCString();
        document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }

    useEffect(() => {
        const handleClick = () => {
            document.querySelector("#cookies").style.display = "none";
            setCookie("cookie", true, 90);
        };

        document.querySelector("#cookies-btn").addEventListener("click", handleClick);

        // Cleanup the event listener when the component unmounts
        return () => {
            document.querySelector("#cookies-btn").removeEventListener("click", handleClick);
        };
    }, []); // Empty dependency array ensures that the effect runs only once on mount

    return (
        <div>
            {/* Your component JSX here */}
        </div>
    );
}

export default CookieSkript;
